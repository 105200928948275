<template lang="pug">
.affiliates.view(v-if="subscriber.loggedIn()")
    section
        .section-content-wrapper
            h1 Приложение
            h3 к <router-link :to="{name: 'agreement'}">Предложению</router-link> (оферте) заключить соглашение об использовании Сайта
            h4
                b Список третьих лиц, в отношении которых Абонент даёт согласие на передачу и обработку своих персональных данных.
            div
                p ООО «Т2 Мобайл»
                p АО "АКОС"
                p ООО "АНТАРЕС"
                p АКЦИОНЕРНОЕ ОБЩЕСТВО "АПЕКС"
                p АКЦИОНЕРНОЕ ОБЩЕСТВО "АРКТИК РЕГИОН СВЯЗЬ"
                p Астарта
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "АСТРАН"
                p НЕПУБЛИЧНОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО "ВАЙНАХ ТЕЛЕКОМ"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ВЕСТКОЛЛ"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ВИКОМ"
                p АКЦИОНЕРНОЕ ОБЩЕСТВО "ВОЕНТЕЛЕКОМ"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ВТБ МОБАЙЛ"
                p ПУБЛИЧНОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО "ВЫМПЕЛ-КОММУНИКАЦИИ"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГАЗПРОМ ТЕЛЕКОМ"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГАЛС-ТЕЛЕКОМ"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГЛОБАЛ ТЕЛЕКОМ"
                p АКЦИОНЕРНОЕ ОБЩЕСТВО "ГЛОНАСС"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ДЕЛЬТА ТЕЛЕКОМ"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ДЭНИ КОЛЛ"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЕКАТЕРИНБУРГ-2000"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИНТЕРНАЦИОНАЛЬНЫЕ ТЕЛЕКОММУНИКАЦИИ"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИНТЕРНОД"
                p ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "СКАРТЕЛ"
                p АКЦИОНЕРНОЕ ОБЩЕСТВО "КАНТРИКОМ"
                p Квантек
                p Компания ТрансТелеКом
                p КОМСТАР-Регионы
                p Крымтелеком
                p К-телеком
                p КТК ТЕЛЕКОМ
                p Курганский сотовый телефон
                p Лайкамобайл
                p МАТРИКС телеком
                p Мегафон
                p Межрегиональный ТранзитТелеком
                p Миател
                p МИТ-ТЕЛ
                p Мобильная государственная связь
                p Мобильная Сотовая Связь Ставрополья
                p Мобильные ТелеСистемы
                p Московская городская телефонная сеть
                p МСН Телеком
                p МТТ Инвест
                p Навигационно-информационные системы
                p НМК
                p Нэт Бай Нэт Холдинг
                p Остелеком
                p Плинтрон
                p Региональный Технический Центр
                p Ростелеком
                p СанСим
                p Сбербанк-Телеком
                p СВЯЗЬРЕСУРС-МОБАЙЛ
                p СЕВАСТОПОЛЬ ТЕЛЕКОМ
                p Северо-Восточные Телекоммуникации
                p СевТелекомСвязь
                p Сибирьтелеком
                p Сигма Телеком
                p СИМ Телеком
                p Синтоник
                p Скай Нетворкс
                p СкайНэт
                p СМС ТРАФИК
                p Сонет
                p Сотел - Сотовая Связь Чувашии
                p Сотел - Читинская Сотовая Связь
                p Сотовая Связь Башкортостана
                p Сотовая Связь Биробиджана
                p Сотовая Телефонная Связь
                p Спринт
                p СТелС
                p Тандер
                p Таттелеком
                p ТВЕ-ТЕЛЕКОМ
                p Твои мобильные технологии
                p Теле2-Санкт-Петербург
                p Тинькофф Мобайл
                p Тривон Нетворкс
                p Тюменьруском
                p ЦЕНТР 2М
                p Центральный телеграф
                p Цифра Один
                p Экспресс Телеком
                p Элемтэ-Инвест
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'subscriber',
        ]),
    },
}
</script>

<style lang="scss" scoped>
.affiliates {
    background: $color--background;
    color: $color--black;
}

</style>
